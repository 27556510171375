import React from "react"
import styled from "styled-components"
import { GlobalStyle } from "~components/Layout/GlobalStyle"
import SEO from "~components/Layout/seo"
import PropTypes from "prop-types"
import { mobile } from "~utilities/mediaQuery"

const Layout = (props) => {
  return (
    <Wrapper>
      <SEO {...props} />
      <GlobalStyle />
      {props.children}
    </Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Wrapper = styled.div`
  position: relative;
  display: flex;
  background-color: #11121a;

  ${mobile} {
    flex-direction: column;
  }
`
