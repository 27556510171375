import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import DesktopNav from "./Desktop"
import MobileNav from "./Mobile"

const Nav = (props) => {
  // fetch data
  const {
    prismic: {
      allDocss: { edges },
    },
  } = useStaticQuery(
    graphql`
      query {
        prismic {
          allDocss {
            edges {
              node {
                title
                slug
              }
            }
          }
        }
      }
    `
  )

  return (
    <>
      <DesktopNav docs={edges} className="render-over-mobile" {...props} />
      <MobileNav docs={edges} className="render-below-mobile" {...props} />
    </>
  )
}

export default Nav
