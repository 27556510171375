import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Icon from "~assets/icon"

const DesktopNav = ({ className, docs, cur }) => {
  return (
    <Wrapper className={className}>
      <FixedWrapper>
        <Link to={`/`}>
          <Icon icon="logo" width="105" />
        </Link>

        <Content>
          {docs.map((doc) => (
            <Link
              key={doc.node.slug}
              to={`/docs/${doc.node.slug}`}
              className={`${doc.node.slug === cur ? "active" : ""}`}
            >
              {doc.node.title}
            </Link>
          ))}
        </Content>
      </FixedWrapper>
    </Wrapper>
  )
}

export default DesktopNav

const Wrapper = styled.div`
  width: 320px;
  padding: 40px 0 0 60px;
  background-color: var(--color-bg-1);
  flex-shrink: 0;

  @media (max-width: 1280px) {
    width: 250px;
    padding: 40px 0 0 40px;
  }
`

const FixedWrapper = styled.div`
  position: fixed;
`

const Content = styled.div`
  margin-top: 72px;
  a {
    display: block;
    position: relative;
    margin-bottom: 40px;
    padding-left: 10px;
    color: var(--color-text);
    font-family: "SF Pro", Arial, Helvetica, sans-serif;
    font-size: 16px;
    text-decoration: none;

    &.active {
      font-weight: bold;
      color: var(--color-white);

      &::before {
        opacity: 1;
      }
    }

    &::before {
      content: "";
      height: calc(100% + 12px);
      width: 1px;
      position: absolute;
      top: -6px;
      left: 0;
      background-color: var(--color-white);
      opacity: 0;
      transition: opacity 0.7s;
      will-change: opacity;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }
`
