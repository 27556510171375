import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Icon from "~assets/icon"

const MobileNav = ({ className, docs, cur }) => {
  const [open, setOpen] = useState(false)

  return (
    <Wrapper className={className}>
      <FixedWrapper>
        <Link to={`/`}>
          <Icon icon="logo" width="95" style={{ marginTop: "13px" }} />
        </Link>

        <Burger onClick={() => setOpen(!open)} open={open}>
          <div />
          <div />
          <div />
        </Burger>
      </FixedWrapper>

      <Content className={open && "active"}>
        {docs.map((doc) => (
          <Link
            key={doc.node.slug}
            to={`/docs/${doc.node.slug}`}
            className={`${doc.node.slug === cur ? "active" : ""}`}
          >
            {doc.node.title}
          </Link>
        ))}
      </Content>
    </Wrapper>
  )
}

export default MobileNav

const Wrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`

const FixedWrapper = styled.div`
  width: 100%;
  height: 70px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-bg-1);
  z-index: 20;
  position: relative;
`

const Burger = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 2px;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: ${({ open }) => (open ? "21px" : "20px")};
    height: ${({ open }) => (open ? "3px" : "2px")};
    background: #effffa;
    border-radius: 5px;
    transition: opacity 0.3s linear, transform 0.3s linear, height 0.3s linear;
    will-change: opacity, transform, height;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? 0 : 1)};
      transform: ${({ open }) => (open ? "translateX(5px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

const Content = styled.div`
  background-color: var(--color-bg-1);
  padding: 20px;
  z-index: 19;
  transform: translateY(-102%);
  opacity: 0;
  transition: transform 0.7s, opacity 0.7s;
  will-change: transform, opacity;

  &.active {
    transform: translateY(0);
    opacity: 1;
  }

  a {
    display: block;
    position: relative;
    margin-bottom: 30px;
    padding-left: 10px;
    color: var(--color-text);
    font-family: "SF Pro", Arial, Helvetica, sans-serif;
    font-size: 16px;
    text-decoration: none;

    :last-child {
      margin-bottom: 0;
    }

    &.active {
      font-weight: bold;
      color: var(--color-white);

      &::before {
        opacity: 1;
      }
    }

    &::before {
      content: "";
      height: calc(100% + 12px);
      width: 1px;
      position: absolute;
      top: -6px;
      left: 0;
      background-color: var(--color-white);
      opacity: 0;
    }
  }
`
