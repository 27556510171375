import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { RichText } from "prismic-reactjs"
import { scrollToEleById } from "~utilities/scroll"
import { mobile, tablet } from "~utilities/mediaQuery"
import useWindowDimensions from "~utilities/getDimension"
import Layout from "./Layout"
import Nav from "./Nav"

const DocTemplate = ({ pageContext: { page } }) => {
  const [tocData, setTocData] = useState([])
  const { width } = useWindowDimensions()

  const htmlSerializer = (type, element, content, children, key) => {
    if (type === "heading2") {
      const id = element.text.toLowerCase().replace(/ /g, "-")
      return (
        <h2 id={id} key={key}>
          {element.text}
        </h2>
      )
    }
    return content
  }

  useEffect(() => {
    page.content.forEach((item) => {
      if (item.type === "heading2") {
        const id = item.text.toLowerCase().replace(/ /g, "-")
        setTocData((tocData) => [...tocData, { id, content: item.text }])
      }
    })
  }, [page.content])

  return (
    <Layout title={page.title}>
      <Nav cur={page.slug} />

      <Article>
        <div className="render-over-mobile">
          <h1>{page.title}</h1>
          <p className="date">
            Last Update: {page._meta.lastPublicationDate.substring(0, 10)}
          </p>
        </div>
        <RichText render={page.content} htmlSerializer={htmlSerializer} />
      </Article>

      <TocWrapper>
        <div className="render-below-mobile">
          <h1>{page.title}</h1>
          <p className="date">
            Last Update: {page._meta.lastPublicationDate.substring(0, 10)}
          </p>
          <div className="divider"></div>
        </div>
        <Toc>
          <h5>In this article</h5>
          {tocData.map((item, index) => (
            <p
              key={index}
              onClick={() =>
                scrollToEleById(item.id, width <= 640 ? -100 : -30)
              }
            >
              {item.content}
            </p>
          ))}
        </Toc>
      </TocWrapper>
    </Layout>
  )
}

export default DocTemplate

const Article = styled.article`
  padding: 40px;
  max-width: 50%;

  ${mobile} {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    padding: 0 20px 40px;
    order: 3;
  }

  h1 {
    font-family: "SF Pro", Arial, Helvetica, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: var(--color-white);
    margin-bottom: 32px;
  }

  h2 {
    font-family: "SF Pro", Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: var(--color-white);
    margin: 2em 0 1em;
  }

  p,
  ol,
  li {
    font-size: 14px;
    font-family: "SF Pro", Arial, Helvetica, sans-serif;
    color: var(--color-white);
    margin-bottom: 1em;

    &.date {
      color: var(--color-text);
      font-style: italic;
      margin-bottom: 2em;
    }
  }

  a {
    font-size: 14px;
    font-family: "SF Pro", Arial, Helvetica, sans-serif;
    color: #58a7ff;
    margin-bottom: 1em;
    text-decoration: none;
  }
`

const TocWrapper = styled.div`
  flex: 1;
  justify-content: flex-end;
  flex-shrink: 0;
  display: flex;
  margin: 100px 3%;

  @media (max-width: 1280px) {
    margin: 100px 1%;
  }

  ${tablet} {
    display: none;
  }

  ${mobile} {
    display: flex;
    flex-direction: column;
    margin: 90px 20px 20px;

    h1 {
      font-family: "SF Pro", Arial, Helvetica, sans-serif;
      font-size: 24px;
      font-weight: bold;
      color: var(--color-white);
      margin-bottom: 5px;
    }

    .date {
      font-size: 14px;
      font-family: "SF Pro", Arial, Helvetica, sans-serif;
      color: var(--color-text);
      font-style: italic;
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: var(--color-bg-1);
      margin: 0 0 20px;
    }
  }
`

const Toc = styled.div`
  position: fixed;
  min-width: 250px;

  ${mobile} {
    position: relative;
    width: 100%;
  }

  h5 {
    font-family: "SF Pro", Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: var(--color-white);
    margin-bottom: 20px;
  }

  p {
    font-family: "SF Pro", Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #58a7ff;
    margin-bottom: 1em;
    cursor: pointer;
  }
`
